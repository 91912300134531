import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
  typography: {
    fontFamily: [
      'Comfortaa',
      'cursive',
    ].join(','),
  },
  palette: {
    primary: {
      light: '#f7e9b6',
      main: '#ffcb08',
      dark: '#000000',
      contrastText: '#fff',
    },
    /*
    secondary: {
      light: '#ff7961',
      main: '#f44336',
      dark: '#ba000d',
      contrastText: '#000',
    },
    */
  },
});