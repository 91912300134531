import axios from "axios";

const gbWaitlistId = 8378;

const waitlistInstance = axios.create({
	baseURL: "https://api.getwaitlist.com/api/v1",
	timeout: 10000,
	headers: { "Content-Type": "application/json" },
});

function addToWaitlist(emailId: string) {
	const body = {
		email: emailId,
		waitlist_id: gbWaitlistId,
	};
	return waitlistInstance
		.post("/waiter", body)
		.then((response) => response.data.referral_link)
		.catch((err) => {
			console.log("error is ", err);
			throw new Error(err.response.data.error_string);
		});
}

export { addToWaitlist };
