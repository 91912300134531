const InfoCard = (props: any) => {
	return (
		<div className='infoCard g-text-center--xs g-radius--10 g-padding-y-15--xs g-padding-x-10--xs'>
			<span className='g-font-size-60--xs g-margin-t-20--xs material-symbols-sharp g-color--dark g-margin-b-40--xs'>{props.icon}</span>
			<p className='g-font-size-22--xs g-color--dark'>{props.heading}</p>
			<p className='g-font-size-14--xs g-color--dark'>{props.content}</p>
		</div>
	);
};

export default InfoCard;
